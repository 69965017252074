<template>
    <div class="all-page">
        <div class="title-box serch-title"  v-if="isShowInput">
            <div class="input-box">
                <input ref="nInput" type="text" v-model="inputContent" @input="serchContent">
                <img v-if="inputContent" src="@/assets/common/clear_input.svg" @click="inputContent=''">
            </div>
            <div class="cancle-btn" @click="hiddenInput">
                取消
            </div>
        </div>
        <div class="title-box" v-else>
            <div class="blank"></div>
            <div class="title-text">攻略</div>
            <div class="serch-btn" @click="openSerchInput">
                <img src="@/assets/common/search.svg">
            </div>
        </div>
        <div class="all-page-content-box" @scroll="scrollList" ref="scrollBox" v-if="strategyList.length>0">
            <div class="strategy-item-box" v-for="(item, index) in strategyList" :key="index" @click="goStrategyDetail(item)">
                <div class="time">
                    {{item.release_time | changTimeType}}
                </div>
                <div class="strategy-item">
                    <div class="back-img-box">
                        <img :src="item.cover_picture">
                        <div class="tag">
                            {{item.news_category_name}}
                        </div>
                    </div>
                    <div class="strategy-info-box">
                        <div class="strategy-title">
                            {{item.title}}
                        </div>
                        <div class="strategy-text">
                           {{item.desc}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="flex:1;" v-else>
            <BlankList style="height: 100%;" type="search" v-show="isShowInput"></BlankList>
            <BlankList style="height: 100%;" type="strategy" v-show="!isShowInput"></BlankList>
        </div>
    </div>
</template>

<script>
    
    import BlankList from "@/components/Common/Vertical/BlankList.vue"
    export default {
        components: {
            BlankList
        },
        props: {
          strategyList:Array,
          isShowInput:Boolean
        },
        data() {
            return {
                inputContent:""
            }
        },
        methods: {
            focusInput() {
                this.$nextTick(()=> {
                    this.$refs.nInput.focus()
                })
            },
            openSerchInput() {
                 this.$emit('openSerchInput')
            },
           serchContent() {
               if(this.inputContent) {
                   this.$emit('serchContent', this.inputContent)
               }
           },
           hiddenInput() {
               this.inputContent = ""
               this.$emit('hiddenInput')
           },
           goStrategyDetail(item) {
               this.$emit('goStrategyDetail', item)
           },
           scrollList(e) {
               this.$nextTick(()=> {
                   this.$emit('scrollList', this.$refs.scrollBox)
               })
           },
        }
    }
</script>

<style scoped>
   .title-box {
       display: flex;
       align-items: center;
       justify-content: space-between;
       width: 282px;
       margin: 0 auto;
       padding-bottom: 22px;
   }
   .serch-title {
       justify-content: center;
   }
   .input-box {
       flex: 1;
       background: #ffffff;
       height: 30px;
       border-radius: 15px;
       display: flex;
       align-items: center;
       justify-content: space-between;
       padding: 0 14px;
       border: 1px solid #DBDBDB;
   }
   .input-box>input {
       border: none;
       flex: 1;
       padding-right: 10px;
   }
   .input-box>img {
       width: 16px;
   }
   .cancle-btn {
       padding-left: 14px;
       font-size: 16px;
       font-weight: 400;
       color: #16C5CE;
   }
   .title-text {
       font-size: 17px;
       font-weight: 500;
       flex: 1;
       text-align: center;
   }
  
   .blank,
   .serch-btn {
       width: 18px;
       display: flex;
       align-items: center;
       justify-content: center;
   }
   .serch-btn>img {
       width: 100%
   }
   .strategy-item-box {
       padding-bottom: 20px;
       width: 282px;
       margin: 0 auto;
   }
   .time {
       width: 100%;
       text-align: center;
       font-size: 12px;
       font-weight: 400;
       color: #999999;
       padding-bottom: 7px;
   }
   .strategy-item {
       width: 100%;
       height: 222px;
       background-color: #F4F4F4;
       border-radius: 9px;
   }
   .back-img-box {
       width: 100%;
       height: 150px;
       overflow: hidden;
       position: relative;
       display: flex;
       align-items: center;
       justify-content: center;
       border-radius: 9px 9px 0px 0px;
   }
   .back-img-box>img {
       width: 100%;
   }
   .tag {
       position: absolute;
       padding: 6px;
       border-radius: 9px 0px 9px 0px;
       background-color: #FF7400;
       color: #ffffff;
       top: 0;
       left: 0;
   }
   .strategy-info-box {
       padding: 13px 14px;
   }
   .strategy-title {
       font-size: 16px;
       font-weight: 500;
       color: #333333;
       padding-bottom: 7px;
   }
   .strategy-text {
       width: 80%;
       font-size: 12px;
       font-weight: 400;
       color: #666666;
   }
   .strategy-title,
   .strategy-text {
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
   }
   .blank-text {
       width: 282px;
       margin: 30% auto;
       font-size: 13px;
       font-weight: 400;
       color: #666666;
       text-align: center;
   }
 </style>
