<template>
    <div>
            <Horizontal v-if="$store.getters.currentPageVersion === 'Horizontal'"
            :strategyList="dataList"
            :isShowInput="isShowInput"
            @scrollList="scrollList"
            @hiddenInput="hiddenInput"
            @serchContent="serchContent"
            @openSerchInput="openSerchInput"
            @goStrategyDetail="goStrategyDetail"></Horizontal>
            <Vertical ref="Vertical" v-else
            :strategyList="dataList"
            :isShowInput="isShowInput"
            @scrollList="scrollList"
            @hiddenInput="hiddenInput"
            @serchContent="serchContent"
            @openSerchInput="openSerchInput"
            @goStrategyDetail="goStrategyDetail"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Strategy/Index/Horizontal/Index.vue'
    import Vertical from '@/components/Strategy/Index/Vertical/Index.vue'


    export default {
        components: {
            Horizontal,
            Vertical
        },
        data() {
            return {
                dataList:[],
                isShowInput:false,
                currentPage:1,
                limit:10,
                inputText:""
            }
        },
       created() {
       	this.getList()
       },
        methods: {
            getList() {
                let requestMethods = null,
                	requestData = {
                		user_id: this.$store.state.userId,
                		token: this.$store.state.token,
                		page:this.currentPage,
                		limit:this.limit,
                        wd:this.inputText
                	}
               this.$api.game.getStrategyList(requestData).then(res => {
                	if (res.data.code === 200) {
                		this.dataList = this.dataList.concat(res.data.data)
                	}
                })
            },
            //滚动列表触发
            scrollList(e) {
                this.$common.pagingRequest(e, this)
            },
            hiddenInput() {
                this.dataList = []
                this.isShowInput=false
                this.currentPage = 1
                this.inputText = ""
                this.getList()
            },
            serchContent(inputText) {
                this.dataList = []
                this.currentPage = 1
                this.inputText = inputText
                this.getList()
            },
            openSerchInput() {
                this.isShowInput = true
                this.$refs.Vertical.focusInput()
            },
            goStrategyDetail(item) {
                this.$router.push({
                    name: 'StrategyDetail',
                    params: {
                        item
                    }
                })
            }
        }
    }
</script>

<style scoped>
    
</style>
